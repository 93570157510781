exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".image-module-root{display:flex;justify-content:center}.image-module-container{position:relative;max-height:100%}.image-module-loaded{position:relative;top:0;left:0;visibility:visible}.image-module-notLoaded{visibility:hidden}.image-module-placeholder{background-color:rgb(var(--venia-global-color-gray));position:relative;top:0;left:0}.image-module-placeholder_layoutOnly{background-color:unset}", "",{"version":3,"sources":["/opt/buildhome/repo/src/overrides/venia-ui/components/Image/image.module.scss"],"names":[],"mappings":"AAAA,mBAKI,YAAA,CACA,sBAAA,CAGJ,wBACI,iBAAA,CACA,eAAA,CAUJ,qBACI,iBAAA,CACA,KAAA,CACA,MAAA,CACA,kBAAA,CAGJ,wBAEI,iBAAA,CAGJ,0BACI,oDAAA,CACA,iBAAA,CACA,KAAA,CACA,MAAA,CAGJ,qCAEI,sBAAA","file":"image.module.scss","sourcesContent":[".root {\n    /*\n     * For customization, we provide an empty root.\n     * These styles will be applied to the image container.\n     */\n    display: flex;\n    justify-content: center;\n}\n\n.container {\n    position: relative;\n    max-height: 100%;\n}\n\n.image {\n    /*\n     * For customization, we provide an empty image class.\n     * These styles will be applied directly to the image itself.\n     */\n}\n\n.loaded {\n    position: relative;\n    top: 0;\n    left: 0;\n    visibility: visible;\n}\n\n.notLoaded {\n    composes: loaded;\n    visibility: hidden;\n}\n\n.placeholder {\n    background-color: rgb(var(--venia-global-color-gray));\n    position: relative;\n    top: 0;\n    left: 0;\n}\n\n.placeholder_layoutOnly {\n    composes: placeholder;\n    background-color: unset;\n}\n"]}]);

// Exports
exports.locals = {
	"root": "image-module-root",
	"container": "image-module-container",
	"loaded": "image-module-loaded",
	"notLoaded": "image-module-notLoaded image-module-loaded",
	"placeholder": "image-module-placeholder",
	"placeholder_layoutOnly": "image-module-placeholder_layoutOnly image-module-placeholder"
};