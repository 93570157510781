exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".radioGroup-module-radioContainer{gap:5px;align-items:center;margin:5px;position:relative;cursor:pointer}.radioGroup-module-radioContainer .icon-container{position:absolute;z-index:1;left:5px}.radioGroup-module-message{font-size:1rem;margin-top:1rem}.radioGroup-module-message:empty{display:none}.radioGroup-module-isHorizontal{display:flex;gap:20px;min-height:50px}.radioGroup-module-isHorizontal>*{display:flex}.radioGroup-module-isVertical{display:flex;flex-direction:column}.radioGroup-module-isVertical .radioGroup-module-radioContainer{display:flex}", "",{"version":3,"sources":["/opt/buildhome/repo/src/overrides/venia-ui/components/RadioGroup/radioGroup.module.scss"],"names":[],"mappings":"AAGA,kCACI,OAAA,CACA,kBAAA,CACA,UAAA,CACA,iBAAA,CACA,cAAA,CAEA,kDACI,iBAAA,CACA,SAAA,CACA,QAAA,CAIR,2BACI,cAAA,CACA,eAAA,CAGJ,iCACI,YAAA,CAGJ,gCACI,YAAA,CACA,QAAA,CACA,eAAA,CACA,kCACI,YAAA,CAIR,8BACI,YAAA,CACA,qBAAA,CACA,gEACI,YAAA","file":"radioGroup.module.scss","sourcesContent":["@import \"../../../../assets/scss/uikit/variables\";\n@import \"../../../../assets/scss/global/variables-custom\";\n\n.radioContainer {\n    gap: 5px;\n    align-items: center;\n    margin: 5px;\n    position: relative;\n    cursor: pointer;\n\n    :global .icon-container {\n        position: absolute;\n        z-index: 1;\n        left: 5px;\n    }\n}\n\n.message {\n    font-size: 1rem;\n    margin-top: 1rem;\n}\n\n.message:empty {\n    display: none;\n}\n\n.isHorizontal {\n    display: flex;\n    gap: 20px;\n    min-height: 50px;\n    > * {\n        display: flex;\n    }\n}\n\n.isVertical {\n    display: flex;\n    flex-direction: column;\n    .radioContainer {\n        display: flex;\n        // justify-content: space-between;\n    }\n}\n"]}]);

// Exports
exports.locals = {
	"radioContainer": "radioGroup-module-radioContainer",
	"message": "radioGroup-module-message",
	"isHorizontal": "radioGroup-module-isHorizontal",
	"isVertical": "radioGroup-module-isVertical"
};